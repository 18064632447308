import React, { useEffect, useState } from 'react';
import config from '@/config';
import BoardColumn from './subcomponents/BoardColumn';
import IssueDetails from '../IssueDetails/IssueDetails';
import './IssueBoard.scss';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourseIssues, updateCourseIssue } from '../../redux/slices/courseIssuesSlice';
import { fetchCourseIssueRelationshipTypes } from '@/review/redux/slices/courseIssueRelationshipTypesSlice';
import { fetchCourseIssueStatuses } from '../../redux/slices/courseIssueStatusesSlice';
import { fetchUsers } from '@/review/redux/slices/usersSlice';
import { fetchCourseIssuePriorities } from '@/review/redux/slices/courseIssuePrioritiesSlice';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import IssueCard from './subcomponents/IssueCard';
const IssueBoard = (props) => {
  const { openGeneralTab } = props;

  const courseID = config.courseID;
  const dispatch = useDispatch();
  const issuesState = useSelector((state) => state.courseIssues);
  const statusesState = useSelector((state) => state.courseIssueStatuses);

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState('');
  const [selectedIssueId, setSelectedIssueId] = useState(null);
  const [draggingIssueId, setDraggingIssueId] = useState(null);
  const [hoveringColumn, setHoveringColumn] = useState(null);
  const [dragCoords, setDragCoords] = useState({ x: 0, y: 0 });

  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const selectedIssue = issuesState.issues.find((issue) => issue.id === selectedIssueId);

  const filterTypes = [
    {
      key: 'my_issues',
      label: 'My Issues',
      disabled: filters.unassigned,
      rule: (issue) => issue.assignee_id === config.my_user_id,
    },
    {
      key: 'assigned',
      label: 'Assigned',
      disabled: filters.unassigned || filters.my_issues,
      rule: (issue) => issue.assignee_id,
    },
    {
      key: 'unassigned',
      label: 'Unassigned',
      disabled: filters.assigned || filters.my_issues,
      rule: (issue) => !issue.assignee_id,
    },
    {
      key: 'high_priority',
      label: 'High Priority',
      disabled: filters.low_priority || filters.no_priority,
      rule: (issue) => (issue.priority?.value ?? 0) >= 3,
    },
    {
      key: 'low_priority',
      label: 'Low Priority',
      disabled: filters.high_priority || filters.no_priority,
      rule: (issue) => (issue.priority?.value ?? 5) < 3,
    },
    {
      key: 'no_priority',
      label: 'No Priority',
      disabled: filters.high_priority || filters.low_priority,
      rule: (issue) => !issue.priority,
    },
    {
      key: 'incomplete',
      label: 'Incomplete',
      rule: (issue) => issue.status.prettyname !== 'Done',
    },
    {
      key: 'has_comments',
      label: 'Has Comments',
      rule: (issue) => issue.comments.length > 0,
    },
    {
      key: 'has_attachment',
      label: 'Has Attachment',
      rule: (issue) => issue.attachments.length > 0,
    },
  ];

  const filteredIssues = issuesState.issues.filter((issue) => {
    // if (issue.id == draggingIssueId) return false;
    if (search) {
      const searchTrim = search.trim().toLowerCase();

      if ((issue?.description ?? '').toLowerCase().includes(searchTrim)) {
        return true;
      }

      if (issue.ticket_number && ('#' + issue.ticket_number).toLowerCase().includes(searchTrim)) {
        return true;
      }

      //   t1p1 style location search
      if (issue.location) {
        const location = issue.location;
        if (location.isHome && 'homepage'.includes(searchTrim)) {
          return true;
        }

        if (typeof location.topicIndex === 'number' && typeof location.pageIndex === 'number') {
          const t = location.topicIndex + 1;
          const p = location.pageIndex + 1;
          if (`t${t}p${p}`.includes(searchTrim)) {
            return true;
          }
        }
      } else if ('unknown location'.includes(searchTrim)) {
        return true;
      }

      if (issue.assignee) {
        const bigsearch = issue.assignee.first_name + ' ' + issue.assignee.last_name + ' ' + issue.assignee.email;
        if (bigsearch.toLowerCase().includes(searchTrim)) {
          return true;
        }
      }

      return false;
    }

    for (const filter of filterTypes) {
      if (filters[filter.key] && !filter.rule(issue)) {
        return false;
      }
    }

    return true;
  });

  const draggingIssue = issuesState.issues.find((issue) => issue.id === draggingIssueId);

  const columnElements = document.querySelectorAll('.BoardColumn .issues');

  const handleDragMove = (event) => {
    if (!draggingIssueId) return;
    event.preventDefault();
    event.stopPropagation();

    // update coords
    setDragCoords({
      x: event.clientX,
      y: event.clientY,
    });

    let newHoveringColumn = null;
    columnElements.forEach((column) => {
      const rect = column.getBoundingClientRect();
      if (
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom &&
        event.clientX >= rect.left &&
        event.clientX <= rect.right
      ) {
        newHoveringColumn = column.dataset.statusId;
      }
    });
    setHoveringColumn(newHoveringColumn);
  };

  const handleDragCancel = () => {
    setDraggingIssueId(null);
  };

  const handleDragDrop = (event) => {
    if (hoveringColumn && draggingIssue) {
      if (draggingIssue.status_id !== hoveringColumn) {
        dispatch(updateCourseIssue({ id: draggingIssue.id, updatedData: { status_id: hoveringColumn } }));
      }
    }

    // Reset drag state:
    setDraggingIssueId(null);
    setHoveringColumn(null);
  };
  const handleDragPickup = (id, e) => {
    setDraggingIssueId(id);

    setDragCoords({
      x: e.clientX,
      y: e.clientY,
    });
  };

  useEffect(() => {
    dispatch(fetchCourseIssueStatuses());
    dispatch(fetchUsers());
    dispatch(fetchCourseIssuePriorities());
    dispatch(fetchCourseIssueRelationshipTypes());
  }, [dispatch]);

  useEffect(() => {
    if (courseID) {
      dispatch(fetchCourseIssues(courseID));
    }
  }, [dispatch, courseID]);

  return (
    <div className='IssueBoard'>
      {selectedIssue && <IssueDetails issue={selectedIssue} backToBoard={() => setSelectedIssueId(null)} />}
      <div className={'filters'}>
        <div className={'group'}>
          <div className={'board-select'}>
            <button className='section-pill' onClick={openGeneralTab}>
              General Comments
            </button>
            <button className='section-pill primary' disabled>
              Issue Board
            </button>
          </div>
          <div className={'divider'}></div>
          {/* <StyledTextInput value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder={'Search'} /> */}
          <div className={'searchbar'}>
            <SVGIcon type='magnify' fill={'#707070'} size={24} />
            <input type='text' placeholder='Search' value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
          </div>
        </div>

        <div className={'group'}>
          <div className={'active-filters'}>
            {filterTypes
              .filter((filter) => filters[filter.key])
              .map((filter) => {
                return (
                  <div className={'filter-active'} key={filter.key}>
                    <div className={'text'}>{filter.label}</div>
                    <StyledButton
                      close
                      iconStyle={{ transform: 'scale(0.6)' }}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          [filter.key]: !filters[filter.key],
                        });
                      }}
                    />
                  </div>
                );
              })}
          </div>

          <select
            className='filter-add'
            onChange={(e) => {
              const val = e.currentTarget.value;
              setFilters({
                ...filters,
                [val]: !filters[val],
              });
            }}
          >
            <option default value=''>
              Filters
            </option>
            {filterTypes
              .filter((filter) => {
                return !filters[filter.key];
              })
              .map((filter) => {
                return <option key={filter.key} label={filter?.label} disabled={filter.disabled} value={filter.key} />;
              })}
          </select>

          <div className={'divider'}></div>

          <div className={'refresh-btn'}>
            <StyledButton issueBoard onClick={refresh} disabled={issuesState.loading}>
              {issuesState.loading ? 'Loading' : 'Refresh'}
            </StyledButton>
          </div>
        </div>
      </div>
      <div className={'board'}>
        {draggingIssue && (
          <div
            onMouseMove={handleDragMove}
            onMouseUp={handleDragDrop}
            className={'dragging-issue'}
            style={{
              top: dragCoords.y - 50,
              left: dragCoords.x - 175,
              position: 'fixed',
              zIndex: 100,
            }}
          >
            <IssueCard dragging={true} issue={draggingIssue} key={draggingIssue.id} />
          </div>
        )}
        <div className={'columns'} onMouseMove={handleDragMove} onMouseUp={handleDragCancel}>
          {statusesState.statuses.map((status, index) => {
            return (
              <BoardColumn
                draggingIssueId={draggingIssueId}
                setSelectedIssueId={setSelectedIssueId}
                hovering={hoveringColumn == status.id}
                isDragSource={draggingIssue?.status_id === status.id}
                key={status.id}
                pickupIssue={handleDragPickup}
                status={status}
                issues={filteredIssues.filter((issue) => issue.status_id === status.id)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IssueBoard;
