import React, { useEffect, useState } from 'react';

const PriorityIndicator = (props) => {
  const { priority, size = 8, fontSize = 10 } = props;

  const priorityColors = {
    low: '#A6CB07',
    medium: '#D9C700',
    high: '#E88F00',
    critical: '#CB0909',
  };

  const priorityText = {
    low: 'LOW PRIORITY',
    medium: 'MEDIUM PRIORITY',
    high: 'HIGH PRIORITY',
    critical: 'CRITICAL PRIORITY',
  };

  const color = priorityColors[priority] ?? '#707070';

  return (
    <div
      className='PriorityIndicator'
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className={'circle'}
        style={{
          backgroundColor: color,
          borderRadius: '50%',
          width: size + 'px',
          height: size + 'px',
          marginRight: '6px',
        }}
      ></div>
      <div
        className={'label'}
        style={{
          fontFamily: 'Roboto Condensed',
          fontWeight: '500',
          fontSize: fontSize + 'px',
          letterSpacing: '0.24px',
          color: '#707070',
          textTransform: 'uppercase',
        }}
      >
        {priorityText[priority] ?? 'NO PRIORITY'}
      </div>
    </div>
  );
};

export default PriorityIndicator;
