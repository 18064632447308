import React from 'react';
import './HeaderBar.scss';
import HeaderUserDetails from './partials/HeaderUserDetails';
import HeaderLogo from './partials/HeaderLogo';
import AuthCheck from '@/authcheck/AuthCheck';
import ChatOverlay from '@/chat/ChatOverlay';
import SupportOverlay from '@/support/SupportOverlay';
import ReviewOverlay from '@/review/ReviewOverlay';
import HeaderAuth from './partials/HeaderAuth';
import config from '@/config';
import { Provider } from 'react-redux';
import reviewStore from '@/review/redux/store';
import StyledButton from '@/shared/components/StyledButton/StyledButton';

const HeaderBar = (props) => {
  const { user = config.userData, guestUserDetails = null } = props;
  console.log('guestUserDetails', guestUserDetails);

  const loggedIn = user;

  const isLoginPage = window.location.pathname === '/login';
  const isWelcomePage = window.location.pathname === '/welcome';
  const isCoursePage = window.location.pathname.match(/^\/courses\/\d+$/);
  const loggedOut = !loggedIn && (isLoginPage || isWelcomePage);

  return (
    <div className={`HeaderBar ${isWelcomePage ? 'welcome' : ''}`}>
      <div className={'header-left-side'}>
        <HeaderLogo />
      </div>
      <div className={'header-right-side'}>
        {loggedIn && (
          <div className={'logged-in'}>
            <HeaderUserDetails user={user} />
            <div className={'vertical-line'}></div>
            <AuthCheck />
            <ChatOverlay />
            <SupportOverlay />
            {isCoursePage && config.feedback_tool && (
              <Provider store={reviewStore}>
                <ReviewOverlay />
              </Provider>
            )}
            <div className={'vertical-line'}></div>
          </div>
        )}

        {guestUserDetails?.email && (
          <div className={'logged-in guest'}>
            <HeaderUserDetails guestUserDetails={guestUserDetails} />
            <div className='vertical-line'></div>
          </div>
        )}

        {loggedOut && (
          <div className={'logged-out'}>
            {isLoginPage && (
              <StyledButton green onClick={() => (window.location.href = '/welcome')}>
                Welcome
              </StyledButton>
            )}
            {isWelcomePage && (
              <StyledButton green onClick={() => (window.location.href = '/login')}>
                Sign In
              </StyledButton>
            )}
          </div>
        )}

        <HeaderAuth user={user} guestUserDetails={guestUserDetails} />
      </div>
    </div>
  );
};

export default HeaderBar;
