import ChatAvatar from '@/chat/ChatAvatar';
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCourseIssues } from '@/review/redux/slices/courseIssuesSlice';
import './IssueCommentThread.scss';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import formatDate from '@/shared/helpers/time_format_helper';

const IssueCommentThread = (props) => {
  const { issue } = props;

  const [newCommentText, setNewCommentText] = useState('');

  const dispatch = useDispatch();
  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const submitComment = () => {
    //api/course_issue_comments/
    axios
      .post('/api/course_issue_comments/', {
        course_issue_id: issue.id,
        comment: newCommentText,
      })
      .then((response) => {
        setNewCommentText('');
        refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sortedComments = [...(issue?.comments ?? [])].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return (
    <div className='IssueCommentThread'>
      <h2>Issue Comments</h2>
      <div className={'leave-comment'}>
        <textarea
          placeholder={'Add a comment'}
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.currentTarget.value)}
        />
        {/* <button onClick={submitComment}>Submit</button> */}
        <StyledButton onClick={submitComment} green tight>
          Submit
        </StyledButton>
      </div>
      <div className={'divider'}></div>
      <div className={'comments'}>
        {sortedComments.map((comment, index) => {
          return (
            <div className={'comment-card'} key={comment.id}>
              <div className={'commenter'}>
                <div className={'icon'}>
                  <ChatAvatar
                    user={
                      comment.user || {
                        first_name: comment.first_name,
                        last_name: comment.last_name,
                        email: comment.email,
                      }
                    }
                    size={24}
                    noImage
                    noBorder
                  />
                </div>
                <div className={'name'}>
                  {comment?.user?.first_name || comment.first_name || 'Unknown'}{' '}
                  {comment?.user?.last_name || comment.last_name || 'User'}
                </div>
                <div className={'time'}>{formatDate(new Date(comment.created_at))}</div>
              </div>
              <div className={'body'}>{comment.comment}</div>
            </div>
          );
        })}
        {issue?.comments?.length === 0 && <div className={'empty'}>No comments yet.</div>}
      </div>
    </div>
  );
};

export default IssueCommentThread;
