const app = document.querySelector('#app-root');
const editor = document.querySelector('#editor');

const basepathOverride =
  app && app.getAttribute('data-s3-basepath-override') ? app.getAttribute('data-s3-basepath-override') : false;
const bucket = app && app.getAttribute('data-s3-bucket') ? app.getAttribute('data-s3-bucket') : 'chameleon-4-prod';

const userData = app && app.getAttribute('data-user') ? JSON.parse(app.getAttribute('data-user')) : null;
const courseData = editor ? JSON.parse(editor.getAttribute('data-course')) : null;
export const config = {
  password_is_expired: userData ? userData.password_is_expired : false,
  isDeveloper: userData ? userData.is_developer : false,
  isSuperDuperAdmin: userData ? userData.is_super_duper_admin : false,
  isSuperAdmin: userData ? userData.is_super_admin : false,
  disable_clipboard_icon: userData ? userData.disable_clipboard_icon : false,
  s3_basepath_override: basepathOverride,
  s3_resolved_base: basepathOverride ? basepathOverride : `https://${bucket}.s3.amazonaws.com`,
  s3_bucket: bucket,
  s3_path: courseData?.s3_path ?? null,
  courseData: courseData,
  courseID: courseData?.id ?? null,
  feedback_tool: courseData?.feedback_flag ?? false,
  themes:
    editor && JSON.parse(editor.getAttribute('data-themes')) ? JSON.parse(editor.getAttribute('data-themes')) : null,
  userData: userData,
  my_user_id: userData ? userData.id : null,
  curriculums:
    editor && JSON.parse(editor.getAttribute('data-curriculums'))
      ? JSON.parse(editor.getAttribute('data-curriculums'))
      : null,
};

console.log('config', config);

export default config;

export const biwwLogoURL = `${config.s3_resolved_base}/core/defaults/biLogo.jpg`;
