import React, { useState } from 'react';
import './ReviewPanel.scss';
import StyledTabs from '@/shared/components/StyledTabs/StyledTabs';
import PageTitle from '@/shared/components/PageTitle/PageTitle';
import CommentsBoard from '../CommentsBoard/CommentsBoard';
import IssueBoard from '../IssueBoard/IssueBoard';
const ReviewPanel = (props) => {
  const { open } = props;

  const [selectedTab, setSelectedTab] = useState('issues');

  if (!open) {
    return null;
  }

  return (
    <div className='ReviewPanel'>
      <div className={'review-display'}>
        <div className={'content'}>
          {selectedTab == 'general' && (
            <CommentsBoard
              openIssuesTab={() => {
                setSelectedTab('issues');
              }}
            />
          )}
          {selectedTab == 'issues' && (
            <IssueBoard
              openGeneralTab={() => {
                setSelectedTab('general');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewPanel;
