import React, { useEffect, useState, useRef } from 'react';
import './StyledRichSelect.scss';

const StyledRichSelect = (props) => {
  const {
    options = [],
    focusOnRender = false,
    onChange,
    value,
    placeholder = 'Select...',
    contentStyle = {},
    ...rest
  } = props;

  // Component state
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const ref = useRef(null);
  const focusRef = useRef(null);

  // Update filtered options when search text or options change
  useEffect(() => {
    if (searchText === '') {
      setFilteredOptions(options);
    } else {
      const lowerSearchText = searchText.toLowerCase();
      setFilteredOptions(
        options.filter((option) => {
          const textToSearch = option.searchtext || option.label || '';

          return textToSearch.toLowerCase().includes(lowerSearchText);
        }),
      );
    }
  }, [searchText, options]);

  // Handle option selection
  const handleSelect = (option) => {
    onChange(option.value);
    setIsOpen(false);
    setSearchText('');
  };

  // Get the selected option
  const selectedOption = options.find((opt) => opt.value === value);

  // Render option content, supporting HTML
  const renderOptionContent = (option) => {
    if (option.content) {
      if (React.isValidElement(option.content)) {
        // apply contentStyle
        return React.cloneElement(option.content, { style: { ...option.content.props.style, ...contentStyle } });
      } else if (typeof option.content === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: option.content }} style={contentStyle} />;
      } else {
        return option.content; // For other types like numbers
      }
    } else {
      return option.label;
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (focusOnRender && focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusOnRender, focusRef]);

  return (
    <div className='StyledRichSelect' ref={ref} {...rest}>
      <button ref={focusRef} className={`control ${isOpen ? 'open' : 'closed'}`} onClick={() => setIsOpen(!isOpen)}>
        <div className='placeholder'>{selectedOption ? renderOptionContent(selectedOption) : placeholder}</div>
        <div className='arrow'></div> {/* Down arrow */}
      </button>
      {isOpen && (
        <div className='dropdown'>
          <input
            type='text'
            className='search-input'
            placeholder='Search...'
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
          />
          <div className='options'>
            {filteredOptions.map((option, index) => (
              <div key={index} className='option' onClick={() => handleSelect(option)}>
                {renderOptionContent(option)}
              </div>
            ))}
            {filteredOptions.length === 0 && <div className='no-options'>No options found</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default StyledRichSelect;
