import React from 'react';
import './IssueAttachments.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCourseIssues } from '@/review/redux/slices/courseIssuesSlice';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';

const IssueAttachments = (props) => {
  const { issue } = props;

  const attachments = issue.attachments;
  const inputRef = React.createRef();
  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const handleDelete = (id) => {
    axios
      .delete(`/api/course_issue_attachments/${id}`)
      .then((response) => {
        refresh();
      })
      .catch((error) => {
        console.error('Error deleting issue attachment', error);
      });
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const postBody = new FormData();
    postBody.append('file', file);
    // post 'api/course_issues/{issue}/attachment'
    axios
      .post(`/api/course_issues/${issue.id}/attachment`, postBody)
      .then((response) => {
        inputRef.current.value = '';
        refresh();
      })
      .catch((error) => {
        console.error('Error uploading issue attachment', error);
      });
  };

  const baseName = (url) => {
    return url.split('/').pop();
  };

  return (
    <div className='IssueAttachments'>
      <h2>Attachments</h2>
      <div className={'upload-new'}>
        <StyledButton
          title='Upload Attachment'
          iconOnly
          iconFill={'#8c8c8c'}
          iconSize={12}
          onClick={() => inputRef.current.click()}
          icon='add'
        />
        <input
          type={'file'}
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={uploadFile}
          accept='jpg,jpeg,png,gif,pdf,doc,docx,xls,xlsx,ppt,pptx,txt'
        />
      </div>
      <div className={'current-attachments'}>
        {attachments.length === 0 && <div className={'empty'}>No attachments</div>}
        {attachments.map((attachment, index) => {
          return (
            <div key={attachment.id} className='attachment'>
              <div className={'file'}>
                <SVGIcon type='file' fill='#8C8C8C' size={16} width={12} />
                <div className={'filename'}>{baseName(attachment.url)}</div>
              </div>
              <div className={'actions'}>
                <div className={'download'}>
                  <StyledButton
                    iconOnly
                    icon='download2'
                    iconSize={12}
                    iconFill={'#8C8C8C'}
                    onClick={() => window.open(attachment.url)}
                  />
                </div>
                <div className={'delete'}>
                  <StyledButton
                    close
                    onClick={() => {
                      handleDelete(attachment.id);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IssueAttachments;
