import React from 'react';
import './StyledSelect.scss';

const StyledSelect = (props) => {
  const grey = { props };

  return (
    <div className={'StyledSelect'}>
      {props.label && <label>{props.label}</label>}
      <div className={`select-cont ${grey ? 'grey' : ''}`}>{props.children}</div>
    </div>
  );
};

export default StyledSelect;
