import React, { useEffect, useState } from 'react';

const CountIndicator = (props) => {
  const {
    count = 0,
    active = true,
    radius = 9,

    fontSize = '11px',
  } = props;

  const activeFill = props.activeFill || '#D5D5D5',
    inactiveFill = props.inactiveFill || '#A7A7A7',
    activeColor = props.activeColor || '#1D2D38',
    inactiveColor = props.inactiveColor || '#FFFFFF';

  const circleStyle = {
    width: radius * 2,
    height: radius * 2,
    backgroundColor: active ? activeFill : inactiveFill,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
  };

  const countStyle = {
    color: active ? activeColor : inactiveColor,
    fontSize: fontSize,
    transition: 'color 0.3s',
    fontWeight: 'bold',
    letterSpacing: '-0.17px',
  };

  return (
    <div className='CountIndicator'>
      <div className={'circle'} style={circleStyle}>
        <div className={'count'} style={countStyle}>
          {count < 100 ? count : '99+'}
        </div>
      </div>
    </div>
  );
};

export default CountIndicator;
