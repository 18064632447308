import ChatAvatar from '@/chat/ChatAvatar';
import PriorityIndicator from '@/shared/components/PriorityIndicator/PriorityIndicator';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import React, { useState, useRef } from 'react';

const IssueCard = (props) => {
  const { issue, dragging = false } = props;

  const [mouseIsDown, setMouseIsDown] = useState(false);

  const MAX_DESCRIPTION_LENGTH = 60;
  const MIN_DRAG_DISTANCE = 30;

  const trimmedDescription =
    issue.description.length > MAX_DESCRIPTION_LENGTH
      ? issue.description.substring(0, MAX_DESCRIPTION_LENGTH) + '...'
      : issue.description;

  const initialPosition = useRef(null);

  const onMouseDown = (e) => {
    if (dragging) return;
    setMouseIsDown(true);
    initialPosition.current = { x: e.clientX, y: e.clientY };
  };

  const onMouseMove = (e) => {
    if (dragging) return;
    if (mouseIsDown && initialPosition.current) {
      const dx = e.clientX - initialPosition.current.x;
      const dy = e.clientY - initialPosition.current.y;
      if (Math.sqrt(dx * dx + dy * dy) > MIN_DRAG_DISTANCE) {
        props.pickup(issue.id, e);
        setMouseIsDown(false);
      }
    }
  };

  const onMouseUp = (e) => {
    if (dragging) return;
    props.select();
    setMouseIsDown(false);
    initialPosition.current = null;
  };

  const onKeyDown = (e) => {
    if (dragging) return;
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      props.select();
      setMouseIsDown(false);
    }
  };

  return (
    <button
      className='IssueCard'
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onKeyDown={onKeyDown}
    >
      <div className={'top'}>
        <div className={'text-info'}>
          {/* T1P1 Style location */}
          <div className={'location'}>
            <div className={'number'}>#{issue.ticket_number}</div>
            <div className={'pipe'}></div>
            <div className={'guid-location'}>
              {issue.location?.isHome
                ? 'Homepage'
                : typeof issue.location?.topicIndex === 'number' && typeof issue.location?.pageIndex === 'number'
                  ? `T${issue.location.topicIndex + 1}P${issue.location.pageIndex + 1}`
                  : 'Unknown Location'}
            </div>
          </div>
          {/* description */}
          <div className={'description'}>{trimmedDescription || 'No Description'}</div>
        </div>

        <div className={'assignee'}>
          {issue.assignee?.email && (
            <ChatAvatar user={issue.assignee} size={38} offsets={{ x: 1, y: -1 }} borderOverride={'1px solid #ccc'} />
          )}
        </div>
      </div>
      <div className={'bottom'}>
        {/* priority indicator */}
        <div className={'priority'}>
          {issue.priority?.name && <PriorityIndicator priority={issue.priority?.name} />}
          {/* <PriorityIndicator priority={issue.priority?.name} /> */}
        </div>
        <div className={'counts'}>
          {issue.comments.length > 0 && (
            <div className={'comments'}>
              <SVGIcon type='issueComment' size={12} fill={'#A7A7A7'} />
              <div className={'text'}>{issue.comments.length}</div>
            </div>
          )}
          {issue.comments.length > 0 && issue.attachments.length > 0 && <div className={'divider'}></div>}
          {issue.attachments.length > 0 && (
            <div className={'attachments'}>
              <SVGIcon type='issueAttachment' size={12} fill={'#A7A7A7'} />
              <div className={'text'}>{issue.attachments.length}</div>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

export default IssueCard;
