import React from 'react';
import default_avatar from './default_avatar.png';

import './ChatAvatar.scss';

const ChatAvatar = (props) => {
  const {
    user = {
      first_name: 'Anonymous',
      last_name: 'User',
      email: 'anonymouse@user.com',
    },
    size = 50,
    useDefault = false,
    noBorder = false,
    noImage = true,
    borderOverride = null,
    offsets = { x: 0, y: 0 }, //there is a lot of unfortunate browser math related to font centering - this provides a way to manually center the initials as needed
  } = props;

  let border = '2px solid #848484';
  if (size < 40) {
    border = '1px solid #848484';
  }

  if (noBorder) {
    border = 'none';
  }

  if (borderOverride) {
    border = borderOverride;
  }

  const circleStyle = {
    width: size,
    height: size,
    border: border,
  };

  const fontSize = Math.floor(size / 2);

  const bgstyle = {
    width: '100%',
    height: '100%',
    color: 'white',
    fontWeight: 'bold',
  };

  const lettersStyle = {
    fontSize: fontSize + 'px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    top: offsets.y + 'px',
    left: offsets.x + 'px',
    lineHeight: 1,
    fontFamily: "'Roboto Mono', monospace",
  };

  // Hash function to generate a numerical value from a string
  const hash = (str) => {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
    }

    return hash;
  };

  // Function to convert hash to a hue value for HSL color
  const getHue = (user) => {
    const str = user?.first_name + user?.last_name + user?.email;
    const hashValue = hash(str);
    const hue = Math.abs(hashValue) % 360;

    return hue;
  };

  // Function to get user initials
  const getInitials = (user) => {
    if (user.first_name === 'Anonymous' && user.last_name === 'User') {
      return '?';
    }

    const initials = `${user?.first_name?.charAt(0) || ''}${user?.last_name?.charAt(0) || ''}`;

    return initials.toUpperCase();
  };

  return (
    <div className={`ChatAvatar ${!user ? 'no-user' : ''}`} style={circleStyle}>
      {user?.avatar && !noImage && <img src={user.avatar} alt={`${user.email} avatar`} />}

      {/* User with no avatar */}
      {user && (!user?.avatar || noImage) && !useDefault && (
        <div
          style={{
            ...bgstyle,
            backgroundColor: `hsl(${getHue(user)}, 75%, 40%)`,
          }}
        >
          <div className={'letters'} style={lettersStyle}>
            {getInitials(user)}
          </div>
        </div>
      )}

      {/* No user */}
      {!user && !useDefault && (
        <div
          style={{
            ...bgstyle,
            backgroundColor: '#aaa',
          }}
        >
          <div className={'letters'}>?</div>
        </div>
      )}

      {!user && useDefault && <img src={default_avatar} alt='default avatar' />}
    </div>
  );
};

export default ChatAvatar;
