export default function formatDate(date) {
  const now = new Date();

  // Helper function to check if the date is today
  const isToday = (d) => {
    return d.getDate() === now.getDate() && d.getMonth() === now.getMonth() && d.getFullYear() === now.getFullYear();
  };

  // Helper function to check if the date was yesterday
  const isYesterday = (d) => {
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    return (
      d.getDate() === yesterday.getDate() &&
      d.getMonth() === yesterday.getMonth() &&
      d.getFullYear() === yesterday.getFullYear()
    );
  };

  // Format the time
  const timeOptions = { hour: 'numeric', minute: '2-digit' };
  const timeString = date.toLocaleTimeString(undefined, timeOptions);

  // Determine the final format based on how recent the date is
  if (isToday(date)) {
    return `Today, ${timeString}`;
  } else if (isYesterday(date)) {
    return `Yesterday, ${timeString}`;
  } else {
    // Return locale date string for non-recent dates
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  }
}

// Example usage:
console.log(formatDate(new Date())); // "Today, 4:05 PM"
console.log(formatDate(new Date('2023-12-31'))); // "Dec 31, 2023, 12:00 AM"
