import React from 'react';
import IssueCard from './IssueCard';
import CountIndicator from '@/shared/components/CountIndicator/CountIndicator';
const BoardColumn = (props) => {
  const {
    status,
    issues = [],
    setSelectedIssueId = () => {},
    pickupIssue = () => {},
    draggingIssueId = null,
    hovering = false,
    isDragSource = false,
  } = props;

  const dropMode = draggingIssueId;

  return (
    <div className='BoardColumn'>
      <div className={'title'}>
        <div className={'name'}>{status.prettyname}</div>
        <div className={'count'}>
          <CountIndicator
            count={issues.length}
            radius={12}
            fontSize={'13px'}
            activeFill={'#FFFFFF'}
            activeColor={'#10B058'}
          />
        </div>
      </div>

      <div className={'issues'} data-status-id={status.id}>
        {dropMode && (
          <div className={`dropzone ${hovering ? 'active' : ''}`}>
            <div className={'label'}>{`${isDragSource ? 'Keep in' : 'Move to'} ${status.prettyname}`}</div>
          </div>
        )}
        {!dropMode &&
          issues
            .sort((a, b) => {
              //  sort by priority then updated at
              if (a.priority_id !== b.priority_id) {
                return a.priority_id > b.priority_id ? -1 : 1;
              }

              //   no updated at?
              if (!a.updated_at) return -1;
              if (!b.updated_at) return 1;

              return new Date(b.updated_at) - new Date(a.updated_at);
            })
            .map((issue, index) => {
              return (
                <IssueCard
                  issue={issue}
                  dragging={draggingIssueId === issue.id}
                  pickup={pickupIssue}
                  select={() => setSelectedIssueId(issue.id)}
                  key={issue.id}
                />
              );
            })}
      </div>
    </div>
  );
};

export default BoardColumn;
