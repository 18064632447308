import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import './StyledButton.scss';

const StyledButton = (props) => {
  const { classOverride = null, iconFill = false, iconSize = false, iconStyle } = props;

  const icon = props.icon ?? null;
  const slim = props.slim ?? false;
  const clear = props.clear ?? false;
  const quiet = props.quiet ?? false;
  const chunky = props.chunky ?? false;
  const greySlim = props.greySlim ?? false;
  const uploader = props.uploader ?? false;
  const green = props.green ?? false;
  const grey = props.grey ?? false;
  const danger = props.danger ?? false;
  const roundIcon = props.roundIcon ?? false;
  const tight = props.tight ?? false;
  const borderless = props.borderless ?? false;
  const issueBoard = props.issueBoard ?? false;
  const iconOnly = props.iconOnly ?? false;

  let className = `StyledButton cml-button cml-button--secondary cml-button--default ${slim ? 'slim' : ''} ${
    clear ? 'clear' : ''
  } ${quiet ? 'quiet' : ''}`;

  if (classOverride) {
    className = classOverride;
  }

  if (chunky) {
    className = 'StyledButton chunky';
  }

  if (greySlim) {
    className = 'StyledButton greySlim';
  }

  if (uploader) {
    className = 'StyledButton uploader';
  }

  if (danger) {
    className = 'StyledButton danger';
  }

  if (props.close) {
    className = 'StyledButton close';
  }

  if (roundIcon) {
    className = 'StyledButton round-icon';
  }

  if (green) {
    className = 'StyledButton green';
  }

  if (grey) {
    className = 'StyledButton grey';
  }

  if (issueBoard) {
    className = 'StyledButton issueBoard';
  }

  if (iconOnly) {
    className = 'StyledButton iconOnly';
  }

  if (tight) {
    className += ' tight';
  }

  if (borderless) {
    className += ' borderless';
  }

  if (props.disabled) {
    className += ' disabled';
  }

  if (props.semidisabled) {
    className += ' semi-disabled';
  }

  return (
    <button
      className={className}
      disabled={props.disabled || false}
      title={props.title ?? props.children}
      onClick={
        props?.onClick ??
        (() => {
          console.error('no click handler');
        })
      }
    >
      {icon && <SVGIcon size={iconSize || 18} type={icon} fill={iconFill} />}
      {props.tooltip && <span className='tooltip'>{props.tooltip}</span>}
      {props.close && <SVGIcon style={iconStyle || {}} size={12} type={'close'} fill={iconFill} />}
      {props.children}
    </button>
  );
};

export default StyledButton;
