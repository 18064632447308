import React, { useEffect, useState } from 'react';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import './IssueDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import StyledSelect from '@/shared/components/StyledSelect/StyledSelect';
import { deleteCourseIssue, fetchCourseIssues, updateCourseIssue } from '@/review/redux/slices/courseIssuesSlice';
import ChatAvatar from '@/chat/ChatAvatar';
import StyledRichSelect from '@/shared/components/StyledRichSelect/StyledRichSelect';
import IssueCommentThread from './subcomponents/IssueCommentThread/IssueCommentThread';
import PriorityIndicator from '@/shared/components/PriorityIndicator/PriorityIndicator';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import formatDate from '@/shared/helpers/time_format_helper';
import IssueAttachments from './subcomponents/IssueAttachments/IssueAttachments';

const IssueDetails = (props) => {
  const { issue, backToBoard = () => {} } = props;
  const dispatch = useDispatch();

  const issuesState = useSelector((state) => state.courseIssues);
  const issues = issuesState.issues;
  console.log('issues', issues);

  const usersState = useSelector((state) => state.users);
  const users = usersState.users;

  const statusesState = useSelector((state) => state.courseIssueStatuses);
  const statuses = statusesState.statuses;

  const prioritiesState = useSelector((state) => state.courseIssuePriorities);
  const priorities = prioritiesState.priorities;

  const relationshipTypesState = useSelector((state) => state.courseIssueRelationshipTypes);
  const relationshipTypes = relationshipTypesState.relationshipTypes;

  const [formData, setFormData] = useState({
    description: issue.description ?? '',
    assignee_id: issue.assignee_id ?? '',
    status_id: issue.status_id ?? '',
    priority_id: issue.priority_id ?? '',
  });

  const [relationships, setRelationships] = useState(issue.relationships || []);

  const [zoomScreenshot, setZoomScreenshot] = useState(false);
  const [escapePressed, setEscapePressed] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    dispatch(updateCourseIssue({ id: issue.id, updatedData: { [name]: value } }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCourseIssue({ id: issue.id, updatedData: formData }));
  };

  const deleteIssue = () => {
    dispatch(deleteCourseIssue({ id: issue.id }));
  };

  const handleClose = () => {
    backToBoard();
  };

  const downloadScreenshot = () => {
    // open in new tab
    window.open(issue.screenshot, '_blank');
  };

  // Relationship helper functions.
  const addRelationship = () => {
    setRelationships([
      ...relationships,
      { id: 'new', issue_id: issue.id, related_issue_id: '', relationship_type_id: '' },
    ]);
  };

  const removeRelationship = (id) => {
    const newRelationships = [...relationships].filter((rel, index) => rel.id !== id);
    setRelationships(newRelationships);
    dispatch(updateCourseIssue({ id: issue.id, updatedData: { relationships: newRelationships } })).then(() => {
      dispatch(fetchCourseIssues());
    });
  };

  const handleRelationshipChange = (index, field, value) => {
    console.log('handleRelationshipChange', index, field, value);
    let newValue = {};
    const newRelationships = [...relationships].map((rel, idx) => {
      if (index == idx) {
        newValue = {
          ...rel,
          [field]: value,
        };

        return newValue;
      }

      return rel;
    });

    if (newValue.related_issue_id !== '' && newValue.relationship_type_id !== '') {
      dispatch(
        updateCourseIssue({
          id: issue.id,
          updatedData: {
            relationships: newRelationships.filter((rel) => {
              return rel.related_issue_id !== '' && rel.relationship_type_id !== '';
            }),
          },
        }),
      ).then(() => {
        dispatch(fetchCourseIssues());
      });
    }

    setRelationships(newRelationships);
  };

  useEffect(() => {
    // listen for escape
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setEscapePressed(true);
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  useEffect(() => {
    if (!escapePressed) return;
    if (zoomScreenshot) {
      setZoomScreenshot(false);
    } else {
      handleClose();
    }

    setEscapePressed(false);
  }, [escapePressed]);

  useEffect(() => {
    setFormData({
      title: issue.title ?? '',
      description: issue.description ?? '',
      assignee_id: issue.assignee_id ?? '',
      status_id: issue.status_id ?? '',
      priority_id: issue.priority_id ?? '',
    });
  }, [issue]);

  return (
    <div className='IssueDetails'>
      <div className={'bg'}></div>

      <div className={'issue-modal'}>
        <div className={'back-to-board'}>
          <StyledButton close title={'Back to Board'} onClick={handleClose} />
        </div>
        <div className={'scroll-wrapper'}>
          {zoomScreenshot && issue?.screenshot && (
            <div className={'zoom-screenshot'}>
              <div className={'top-bar'}>
                <h2>Enlarged Screenshot</h2>
                <button className='download-btn' onClick={downloadScreenshot} title='download screenshot'>
                  <SVGIcon type='download' size={24} fill={'#1D2E39'} />
                </button>
                <StyledButton close onClick={() => setZoomScreenshot(false)}></StyledButton>
              </div>
              <div className={'zoom-img-wrapper'}>
                <img src={issue.screenshot} alt='screenshot' />
              </div>
            </div>
          )}

          {/* issue description heading */}
          <div className={'issue-heading'}>
            <h1>Issue #{issue.ticket_number}</h1>
            <PriorityIndicator size={9} fontSize={12} priority={issue?.priority?.name} />
          </div>

          {/* comment thread */}
          <div className={'comment-thread '}>
            <IssueCommentThread issue={issue} />
          </div>

          {/* description body */}
          <div className={'description-body'}>{issue.description}</div>

          {/* issue details */}
          <div className={'issue-config'}>
            <h2>Issue Details</h2>

            <div className={'field assignee'}>
              <div className={'label'}>Assignee</div>
              <div className={'value'}>
                {
                  <StyledRichSelect
                    focusOnRender={true}
                    value={formData.assignee_id}
                    onChange={(value) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        assignee_id: value,
                      }));
                      dispatch(updateCourseIssue({ id: issue.id, updatedData: { assignee_id: value } }));
                    }}
                    placeholder={'Select Assignee'}
                    contentStyle={{ display: 'flex', alignItems: 'center' }}
                    options={[...users].map((user) => {
                      return {
                        value: user.id,
                        label: user.email,
                        searchValue: `${user.first_name} ${user.last_name} ${user.email}`,
                        content: (
                          <div>
                            <ChatAvatar user={user} size={28} />
                            <div className={'name'} style={{ marginLeft: 10 }}>
                              {user.first_name} {user.last_name}
                            </div>
                          </div>
                        ),
                      };
                    })}
                  />
                }
              </div>
            </div>

            <div className={'field status'}>
              <div className={'label'}>Status</div>
              <div className={'value'}>
                {
                  <StyledSelect grey>
                    <select name={'status_id'} onChange={handleInputChange} value={formData.status_id}>
                      {statuses.map((status, index) => {
                        return (
                          <option key={index} value={status.id}>
                            {status.prettyname}
                          </option>
                        );
                      })}
                    </select>
                  </StyledSelect>
                }
              </div>
            </div>
            <div className='field priority'>
              <div className={'label'}>Priority</div>
              <div className={'value'}>
                <StyledSelect grey>
                  <select name='priority_id' onChange={handleInputChange} value={formData.priority_id || ''}>
                    <option value={''} default>
                      None
                    </option>
                    {priorities.map((priority, index) => {
                      return (
                        <option key={index} value={priority.id}>
                          {priority.name.charAt(0).toUpperCase() + priority.name.slice(1)}
                        </option>
                      );
                    })}
                  </select>
                </StyledSelect>
              </div>
            </div>

            {/* Relationships Field */}
            <div className='field relationships'>
              <div className='label'>Relationships</div>
              <div className='value'>
                {(relationships || []).map((rel, index) => {
                  return (
                    <div key={rel.id + ' ' + index} className='relationship-item'>
                      <div className={'fields'}>
                        <StyledSelect grey>
                          <select
                            value={rel.relationship_type_id}
                            onChange={(e) => handleRelationshipChange(index, 'relationship_type_id', e.target.value)}
                          >
                            <option value=''>Select relationship</option>
                            {relationshipTypes.map((type, idx) => (
                              <option key={idx} value={type.id}>
                                {type.name}
                              </option>
                            ))}
                          </select>
                        </StyledSelect>
                        <StyledRichSelect
                          value={rel.related_issue_id}
                          onChange={(value) => handleRelationshipChange(index, 'related_issue_id', value)}
                          placeholder='Select issue'
                          options={issues
                            .filter((item) => item.id !== issue.id)
                            .map((item) => ({
                              value: item.id,
                              label: `#${item.ticket_number} - ${item.description.substring(0, 15)}`,
                            }))}
                        />
                      </div>

                      <StyledButton close onClick={() => removeRelationship(rel.id)} title='Remove Relationship' />
                    </div>
                  );
                })}
                <StyledButton issueBoard onClick={addRelationship} title='Add Relationship'>
                  Add Relationship
                </StyledButton>
              </div>
            </div>

            <div className={'field big-gap location'}>
              <div className={'label'}>Location</div>
              <div className={'value'}>
                {issue.location?.isHome
                  ? 'Homepage'
                  : typeof issue.location?.topicIndex === 'number' && typeof issue.location?.pageIndex === 'number'
                    ? `T${issue.location.topicIndex + 1}P${issue.location.pageIndex + 1}`
                    : 'Unknown Location'}
              </div>
            </div>

            <div className={'field created-at'}>
              <div className={'label'}>Date Created</div>
              <div className={'value'}>{formatDate(new Date(issue.created_at))}</div>
            </div>

            <div className={'field created-at'}>
              <div className={'label'}>Date Updated</div>
              <div className={'value'}>{formatDate(new Date(issue.updated_at))}</div>
            </div>

            <div className={'field reporter'}>
              <div className={'label'}>Reported By</div>
              <div className={'value user-info'}>
                <ChatAvatar user={issue.reporter} size={32} noBorder />
                <div className={'text'}>
                  <div className={'name'}>
                    {issue.reporter?.first_name ?? 'Anonymous'} {issue.reporter?.last_name ?? 'User'}
                  </div>
                  <div className={'email'}>{issue.reporter?.email ?? 'anonymous@user.com'}</div>
                </div>
              </div>
            </div>

            <div className={'field true-id'}>
              <div className={'label'}>Issue ID Number</div>
              <div className={'value'}>{issue.id}</div>
            </div>
          </div>

          <div className={'files-wrapper'}>
            <div className={'screenshot'}>
              <h2>Screenshot</h2>
              {/* issue.screenshot is url - */}
              <div className={'screenshot-img-wrapper'}>
                {issue.screenshot ? (
                  <div className={'zoom-btn-wrapper'}>
                    <img src={issue.screenshot} alt='screenshot' />
                    <div className={'magnify'}>
                      <button
                        onClick={() => {
                          setZoomScreenshot(true);
                        }}
                      >
                        <SVGIcon type='magnify' size={32} fill={'#fff'} />
                        <span>View</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={'no-screenshot'}>
                    <span>No Screenshot</span>
                  </div>
                )}
              </div>
            </div>
            <div className={'attachments'}>
              <IssueAttachments issue={issue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueDetails;
